// prettier-ignore
import { AppBar, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import { withRoot } from "./withRoot";
import {ShoppingCart} from '@mui/icons-material';
import { CartPage, LoginPage, TodoPage, AddPage } from "./pages";


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
		overflowY: "scroll"
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 56px)",
		marginTop: 56,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 64px)",
			marginTop: 64,
		},
	},
}));

function App() {

	const classes = useStyles();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);

	return (
        <Router>
			<div className={classes.root}>
				<div className={classes.appFrame}>
				
					<AppBar className={classes.appBar}>
						<Toolbar>
							
							<Typography
								variant="h6"
								color="inherit"
								noWrap={isMobile}
							>
								Recipe With Me
							</Typography>
							<span style={{flexGrow: 1}}></span>
							<IconButton component={Link}
                to={
                  "/cart"
                }>
							<ShoppingCart></ShoppingCart></IconButton>
							
											</Toolbar>
					</AppBar>
					<div className={classes.content}>
					<Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<TodoPage />} />
		<Route path="/cart" element={<CartPage  />} />
		<Route path="/add" element={<AddPage  />} />


      </Routes>
					</div>
				</div>
			</div>
		</Router>
    );
}


export default withRoot(App);
