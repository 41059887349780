import { CartRow } from '../model/cart-row.model';
import { CartActions, CartAction } from '../model/cart.state';
import { Recipe } from '../model/recipes.model';


export function addToCart(recipe: Recipe[]): CartAction {
	return { type: CartActions.ADD_TO_CART, payload: recipe };
}

export function checkInCart(rowId: number): CartAction {
	return { type: CartActions.CHECK_IN_CART, payload: rowId };

}

export function uncheckInCart(rowId: number): CartAction {
	return { type: CartActions.UNCHECK_IN_CART, payload: rowId };

}

export function removeFromCart(rowId: number): CartAction {
	return { type: CartActions.REMOVE_FROM_CART, payload: rowId };
}

export function clearCart(): CartAction {
	return { type: CartActions.CLEAR_CART, payload: null };
}

export function addItemToCart(recipe: CartRow): CartAction {
	return { type: CartActions.ADD_ITEM_TO_CART, payload: recipe };
}

export function removeItemFromCart(rowId: number): CartAction {
	return { type: CartActions.REMOVE_ITEM_FROM_CART, payload: rowId };
}

export function setListFromCookies(payload:{recipes: any, groceryList: any}): CartAction {
	return { type: CartActions.LOAD_COOKIES, payload: payload };

}



