export function getItem(key: string): string | null {
    if (!key) {
      return null;
    }
  
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            //eslint-disable-line
            '(?:(?:^|.*;)\\s*' +//eslint-disable-line
              encodeURIComponent(key).replace(/ [\-\.\+\*]/g, '\\$&') +//eslint-disable-line
              '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      ) || null
    );
  }
  
  export function  setItem(
    key: string,
    value: string,
    end?: Number | String | Date,
    path?: string,
    domain?: string,
    secure?: boolean
  ): boolean {
    //eslint-disable-line
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {//eslint-disable-line
      return false;
    }
    let expires = '';
    if (end) {
      switch (end.constructor) {
        case Number:
          expires =
            end === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + end;
          break;
        case String:
          expires = '; expires=' + end;
          break;
        case Date:
          expires = '; expires=' + (end as Date).toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(value) +
      expires +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '') +
      (secure ? '; secure' : '');
  
    return true;
  }
  
  export function  removeItem(key: string, path?: string, domain?: string): boolean {
    if (!hasItem(key)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(key) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (domain ? '; domain=' + domain : '') +
      (path ? '; path=' + path : '');
  
    return true;
  }
  
  export function  hasItem(key: string): boolean {
    if (!key) {
      return false;
    }
  
    return new RegExp(
      '(?:^|;\\s*)' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\='//eslint-disable-line
    ).test(document.cookie);
  }
  
  export function  keys(): string[] {
    const keys: string[] = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')//eslint-disable-line
      .split(/\s*(?:\=[^;]*)?;\s*/);//eslint-disable-line
    const len: number = keys.length;
  
    for (let i = 0; i < len; i++) {
      keys[i] = decodeURIComponent(keys[i]);
    }
    return keys;
  }
  
  export function  cookiesEnabled(): boolean {
    const testCookie = 'TEST_COOKIE';
    let cookieEnabled = navigator.cookieEnabled ? true : false;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      setItem(testCookie, '1');
      cookieEnabled = removeItem(testCookie);
    }
    return cookieEnabled;
  }