import {  Grid,  } from '@mui/material';
import * as React from 'react';
import { useActions } from '../actions';
import * as CartActions from '../actions/cart.actions';
import * as RecipesActions from '../actions/recipes.action';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { get as _get } from 'lodash';
import {  fetchFromCloud, fetchFromCsv,  updateCart } from '../services/excel-reader.service';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getItem } from '../services/cookies.service';



export function AddPage() {
    const [searchParams] = useSearchParams();



	// Auth Block ///////////////////////////////////////////////////////////////
	const [user, loading] = useAuthState(auth);
	// const [name, setName] = useState("");
	const navigate = useNavigate();
	const recipesActions: typeof RecipesActions = useActions(RecipesActions);

	const fetchUserName = async () => {
		try {
			const q = query(collection(db, "users"), where("uid", "==", user?.uid));
			console.log(auth)

			console.log(q)
			const doc = await getDocs(q);
			console.log(doc)
			//setName(data.name);
		} catch (err) {
			console.error(err);
			alert("An error occured while fetching user data");
		}
	};

	useEffect(() => {
		if (loading) return;
        if (!user) return navigate("/", {
            state: {
                fromUrl: '/add?recipeId=' + searchParams.get('recipeId')
            }
        });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.currentUser]);

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/", {
            state: {
                fromUrl: '/add?recipeId=' + searchParams.get('recipeId')
            }
        });
		fetchUserName();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, loading]);

    

	// console.log(name)
	////////////////////////////////////////////////////////////////////////////////////


	const recipeData = useSelector((state: RootState) => _get(state, 'recipesReducer'));

    useEffect(() => {
        console.log('running')

        const loadRecipe = async () => {
            let started = false;
            console.log(recipeData);
            console.log(recipeData && !recipeData?.loading && !recipeData?.hasError && !recipeData?.loaded && !started)
            if (recipeData && !recipeData?.loading && !recipeData?.hasError && !recipeData?.loaded && !started && auth.currentUser) {
                started = true;
                console.log('boop');
                recipesActions.getRecipes();
                const addSuccess = await getRecipesFromAWS(recipesActions, cartActions, searchParams.get('recipeId') || '');
        
                console.log('success?')
                console.log(addSuccess)
                
            if(addSuccess) {
                navigate('/dashboard');
            }
            }

        }
        loadRecipe();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.currentUser]);


	const cartActions: typeof CartActions = useActions(CartActions);







	return (
		<Grid container>
		</Grid>
	);
}





async function getRecipesFromAWS(recipeActions: typeof RecipesActions, cartActions: typeof CartActions, recipeId: string) {
	let data: any = await fetchFromCloud(_get(auth, 'currentUser.accessToken', ''), _get(auth, 'currentUser.email', ''));


	console.log('data')
	console.log(data)


	if (!data) {
		console.log('missed')
		data = await fetchFromCsv();
	}

	const cookies = getItem('cart');
	console.log(cookies);
    let prefillCart = [];
	if(cookies) {
		prefillCart = cookies.split(',').map(itemId => {
			return data.find((item: any) => item.id === parseInt(itemId));
		});

		prefillCart = prefillCart.filter( Boolean );

		if(prefillCart) {
			cartActions.addToCart(prefillCart);
		}
	}


	recipeActions.getRecipesSuccess(data);

    const recipeBody = data.find((item: any) => item.id === parseInt(recipeId));

    prefillCart.push(recipeBody);
    cartActions.clearCart();
    cartActions.addToCart(prefillCart);


    return await updateCart(_get(auth, 'currentUser.accessToken', ''), recipeBody);

}

