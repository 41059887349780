import { startCase as _startCase } from "lodash";
import { CartRow } from "../model/cart-row.model";



export function mapListToTableFormat(ingredientList: string): string {
	const ingredientArray = ingredientList.split(',');
	let mappedIngredients = '';

	ingredientArray.forEach((ingredient: string) => {
		const ingredientPieces = ingredient.split('.');
		if (ingredientPieces.length === 3) {
			const type =ingredientPieces[2]
			if (type) {
				mappedIngredients +=
					mapQuantities(ingredientPieces[1]) + ' ' + _startCase(type) + ' ' + _startCase(ingredientPieces[0]) + ', ';
			} else {
				mappedIngredients += mapQuantities(ingredientPieces[1]) + ' ' + _startCase(ingredientPieces[0]) + ', ';
			}
		}
	});

	return mappedIngredients;
}

export function mapQuantities(quantity: string): string {
	let returnString = '';
	const quantitySplit = quantity.toString().split(' ');
        if(quantitySplit.length === 2) {
            returnString += quantitySplit[0] + ' ' + quantityCodeToEnglish(quantitySplit[1]);
        } else {
            returnString += quantityCodeToEnglish(quantitySplit[0]);

        }
	return returnString;
}

export function quantityCodeToEnglish(code: string): string {
	switch (code) {
		case 'q':
			return '';
		case 'oz':
			return 'oz';
		case 'tbs':
			return 'tablespoons';
		case 'tsps':
			return 'teaspoons';
		case 'c':
			return 'cups';
		case 'lb':
			return 'pounds';
            case 'tt':
                return 'To Taste';
		default:
			return  _startCase(code);
	}
}

export function mapCartToIngredients(cartData: any[]) {
	const itemList: CartRow[] = [];
	console.log(cartData)
	cartData.forEach((recipe) => {
		console.log('recipe')
		console.log(recipe);
		const ingredientPieces = recipe.ingredients.split(',');
		ingredientPieces.forEach((ingredient: string) => {
			const splitIngredients = ingredient.split('.');
			if (splitIngredients.length === 3) {
				const detailedIngredient = {
					id: itemList.length,
					ingredient: splitIngredients[2] + ' ' + splitIngredients[0] + ` - (${recipe.name})`,
					quantity: mapQuantities(splitIngredients[1])
				}
				console.log(splitIngredients);
				const existingItemIndex = itemList.findIndex((item: CartRow) => {
					return item.ingredient === splitIngredients[0];
				});

				if (existingItemIndex === -1) {
					itemList.push({
						id: itemList.length,
						ingredient: splitIngredients[0],
						detailedIngredient: [detailedIngredient],
						quantity: mapQuantities(splitIngredients[1]),
					});
				} else {
					// Check if existing specific detailed ingredient and update quantity, otherwise 
					
					itemList[existingItemIndex].detailedIngredient?.push(detailedIngredient);
					itemList[existingItemIndex].quantity = (
						parseInt(itemList[existingItemIndex].quantity) + parseInt(mapQuantities(splitIngredients[1]))
					).toString();
				}
			}
		});
	});

	console.log(itemList);

	return itemList;
}
