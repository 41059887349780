import * as Papa from 'papaparse';
import {RecipeRow} from '../types/recipe-row';
import { mapListToTableFormat } from '../mappers/ingredient.mapper';
import { setItem } from './cookies.service';

export async function fetchFromCsv() {

	

	let csvData = await fetchCsv();

	let result = Papa.parse(csvData, {complete: function(results, file) {
        //const mappedRecipes = mapRecipesToTable(results.data)
	}});

    console.log(result.data);

    return mapRecipesToTable(result.data);
}

function mapRecipesToTable(recipeList: any[]): RecipeRow[] {
	return recipeList.map((recipe, index) => {
		return {
			id: index,
			name: recipe[0],
			ingredients: mapIngredientsToDisplayString(recipe),
            ingredientsDisplay: mapListToTableFormat(mapIngredientsToDisplayString(recipe)),
			instructions: recipe.instructions
		}
	})

}

function mapCloudRecipesToTable(recipeList: any[]): RecipeRow[] {
	return recipeList.map((recipe, index) => {
		return {
			id: recipe.recipe_id,
			name: recipe.name,
			ingredients: mapIngredientsToDisplayString(recipe.ingredients.split(',')),
            ingredientsDisplay: mapListToTableFormat(mapIngredientsToDisplayString(recipe.ingredients.split(','))),
			instructions: recipe.instructions
		}
	})

}

export function mapIngredientsToDisplayString(ingredientList: any[]): string {
	let ingredientsListString = '';
	for(let i = 0; i < ingredientList.length; i++) {
		ingredientsListString += ingredientList[i] + ','
	}
	return ingredientsListString
}


function fetchCsv() {
	return fetch('data/Recipe_List_V1.csv').then(function (response) {
		if(response.body) {
			let reader = response.body.getReader();
			let decoder = new TextDecoder('utf-8'); 
			return reader.read().then(function (result) {
				return decoder.decode(result.value);
			});
		} else {
			console.log('missing response')
			return ''
		}
	});
}

export async function fetchFromCloud(authToken: string, email: string) {
	return fetch('https://3nsjnln933.execute-api.us-west-1.amazonaws.com/default/fetchRecipes', {headers: {
		'Authorization': 'Bearer ' + authToken,
		'userId': email
		// 'Content-Type': 'application/x-www-form-urlencoded',
	  },}).then(function (response) {
		  console.log(response)
		if(response.body) {

			let reader = response.body.getReader();
			let decoder = new TextDecoder('utf-8'); 
			return reader.read().then(function (result) {
				console.log(decoder.decode(result.value))
				let cloudCart = JSON.parse(decoder.decode(result.value)).cart;
				if(!Array.isArray(cloudCart)) {
					cloudCart = [cloudCart];
				}
				const cartCookie = mapCookies(cloudCart)
				if(cartCookie || cartCookie === '') {
					setItem('cart', cartCookie);
				}
				return mapCloudRecipesToTable(JSON.parse(decoder.decode(result.value)).Items)
			});
		} else {
			console.log('missing response')
			return null;
		}
	})
}


export async function addRecipe(authToken: string, recipeBody: any) {
	return fetch('https://3nsjnln933.execute-api.us-west-1.amazonaws.com/default/addRecipe', {headers: {
		'Authorization': 'Bearer ' + authToken,
		'Content-Type': 'application/json'
	  }, method: "POST", body: JSON.stringify(recipeBody)}).then(function (response) {
		  console.log(response)
		if(response.body) {

			let reader = response.body.getReader();
			let decoder = new TextDecoder('utf-8'); 
			return reader.read().then(function (result) {
				console.log(decoder.decode(result.value))
				return true
			});
			//return _get(, 'body.Items');
		} else {
			console.log('missing response')
			return null;
		}
	})
}

export async function updateCart(authToken: string, recipeIds: any) {
	return fetch('https://3nsjnln933.execute-api.us-west-1.amazonaws.com/default/updateCart', {headers: {
		'Authorization': 'Bearer ' + authToken,
		'Content-Type': 'application/json'
	  }, method: "POST", body: JSON.stringify(recipeIds)}).then(function (response) {
		  console.log(response)
		if(response.body) {

			let reader = response.body.getReader();
			let decoder = new TextDecoder('utf-8'); 
			return reader.read().then(function (result) {
				console.log(decoder.decode(result.value))
				return true;
			});
		} else {
			console.log('missing response')
			return null;
		}
	})
}

export async function removeRecipe(authToken: string, recipeId: string) {
	return fetch(' https://3nsjnln933.execute-api.us-west-1.amazonaws.com/default/removeRecipe?id='+recipeId, {headers: {
		'Authorization': 'Bearer ' + authToken,
		'Content-Type': 'application/json'
	  }, method: "DELETE"}).then(function (response) {
		  console.log(response)
		if(response.body) {

			let reader = response.body.getReader();
			let decoder = new TextDecoder('utf-8'); 
			return reader.read().then(function (result) {
				console.log(decoder.decode(result.value))
				return mapCloudRecipesToTable(JSON.parse(decoder.decode(result.value)).Items)
			});
			//return _get(response, 'body.Items');
		} else {
			console.log('missing response')
			return null;
		}
	})
}

function mapCookies(cart: any) {
	let cookie = '';
	console.log('cart')
	console.log(cart)

	cart.forEach((item: any)=> {
		cookie += item.id + ',';
	})
	return cookie;
}