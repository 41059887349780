import { CartAction, CartActions, CartState, initialCartState } from '../model/cart.state';
import {mapCartToIngredients} from '../mappers/ingredient.mapper'
import createReducer  from './createReducer';
import { CartRow } from '../model/cart-row.model';

  export const cartReducer = createReducer<CartState>(initialCartState, {
    [CartActions.ADD_TO_CART](state: CartState, action: CartAction) {
        console.log(action.payload)
		if(action.type === CartActions.ADD_TO_CART) {
			
			const updatedList = state.recipeList.concat(action.payload);
			const individualItems = state.groceryList.filter(item => item.isIndividual);
			const groceryList = mapCartToIngredients(updatedList).concat(individualItems)
			sessionStorage.setItem('recipeList', JSON.stringify(updatedList));
			sessionStorage.setItem('groceryList', JSON.stringify(groceryList));

			return {...state, loading: false, loaded: true, hasError: false, recipeList: updatedList, groceryList: groceryList};
		} else {
			return {...state, loading: true, loaded: false};
		}
	},
	[CartActions.CHECK_IN_CART](state: CartState, action: CartAction) {
		if(action.type === CartActions.CHECK_IN_CART) {
			const updatedList = state.groceryList;
			const rowsIndex = state.groceryList.findIndex((item: CartRow) => {
				return item.id === action.payload;
			});
			const removedValue = state.groceryList[rowsIndex];
			console.log(state.groceryList);

			state.groceryList.splice(rowsIndex, 1);
			updatedList.push(removedValue);


			return {...state, loading: false, loaded: true, hasError: false, groceryList: updatedList};
		} else {
			return {...state, loading: false, loaded: true, hasError: false};
		}
	},
	[CartActions.UNCHECK_IN_CART](state: CartState, action: CartAction) {
		if(action.type === CartActions.UNCHECK_IN_CART) {
			const rowsIndex = state.groceryList.findIndex((item: CartRow) => {
				return item.id === action.payload;
			});
			const removedValue = state.groceryList[rowsIndex];
			let updateList = state.groceryList.splice(rowsIndex, 1);
			updateList = [removedValue].concat(state.groceryList);

			return {...state, loading: false, loaded: true, hasError: false, groceryList: updateList};
		} else {
			return {...state, loading: false, loaded: true, hasError: false};
		}
	},
    [CartActions.REMOVE_FROM_CART](state: CartState, action: CartAction) {
		if(action.type === CartActions.REMOVE_FROM_CART) {
			const updatedList = state.recipeList.filter(row => row.id !== action.payload)
			const groceryList = mapCartToIngredients(updatedList);
			sessionStorage.setItem('recipeList', JSON.stringify(updatedList));
			sessionStorage.setItem('groceryList', JSON.stringify(groceryList));
			return {...state, loading: false, loaded: true, hasError: false, recipeList: updatedList, groceryList: groceryList};
		} else {
			return {...state, loading: false, loaded: true, hasError: false};
		}
	},
	[CartActions.CLEAR_CART](state: CartState, action: CartAction) {
		sessionStorage.setItem('recipeList', '[]');
		sessionStorage.setItem('groceryList', '[]');
		return {...state, loading: false, loaded: true, hasError: true, recipeList: [], groceryList: []};
	},
	[CartActions.ADD_ITEM_TO_CART](state: CartState, action: CartAction) {
        console.log(action.payload)
		if(action.type === CartActions.ADD_ITEM_TO_CART) {
			const updatedList = state.groceryList.concat(action.payload);
			sessionStorage.setItem('groceryList', JSON.stringify(updatedList));
			return {...state, loading: false, loaded: true, hasError: false, groceryList: updatedList};
		} else {
			return {...state, loading: true, loaded: false};
		}
	},
	[CartActions.REMOVE_ITEM_FROM_CART](state: CartState, action: CartAction) {
		if(action.type === CartActions.REMOVE_ITEM_FROM_CART) {
			const updatedList = state.groceryList.filter(row => row.id !== action.payload);
			sessionStorage.setItem('groceryList',JSON.stringify(updatedList));
			return {...state, loading: false, loaded: true, hasError: false, groceryList: updatedList};
		} else {
			return {...state, loading: false, loaded: true, hasError: false};
		}
	},
	[CartActions.LOAD_COOKIES](state: CartState, action: CartAction) {
        console.log(action.payload)
		if(action.type === CartActions.LOAD_COOKIES) {
			return {...state, loading: false, loaded: true, hasError: false, recipeList: action.payload.recipes, groceryList: action.payload.groceryList};
		} else {
			return {...state, loading: true, loaded: false};
		}
	},
	
});
