import { RecipeRow } from '../types/recipe-row';
import { Recipe } from './recipes.model';

export interface RecipesState {
  hasError: boolean;
  loading: boolean;
  loaded: boolean;
  recipes: Recipe[];
}

export enum RecipesActions {
  GET_RECIPES = "GET_RECIPES",
  GET_RECIPES_SUCCESS = "GET_RECIPES_SUCCESS",
  GET_RECIPES_FAIL = "GET_RECIPES_FAIL",
  ADD_RECIPE = "ADD_RECIPE",
  ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS",
  ADD_RECIPE_FAIL = "ADD_RECIPE_FAIL",
  REMOVE_RECIPE = "REMOVE_RECIPE",
  REMOVE_RECIPE_SUCCESS = "REMOVE_RECIPE_SUCCESS",
  REMOVE_RECIPE_FAIL = "REMOVE_RECIPE_FAIL",
}

interface RecipesActionType<T, P> {
  type: T;
  payload: P;
}

export type RecipesAction =
  | RecipesActionType<typeof RecipesActions.GET_RECIPES, string>
  | RecipesActionType<
    typeof RecipesActions.GET_RECIPES_SUCCESS,
    RecipeRow[]
  >
  | RecipesActionType<
    typeof RecipesActions.GET_RECIPES_FAIL,
    string
  >
  | RecipesActionType<
    typeof RecipesActions.ADD_RECIPE,
    Recipe
  >
  | RecipesActionType<
    typeof RecipesActions.ADD_RECIPE_SUCCESS,
    RecipeRow
  >
  | RecipesActionType<
    typeof RecipesActions.ADD_RECIPE_FAIL,
    string
  >
  | RecipesActionType<
    typeof RecipesActions.REMOVE_RECIPE,
    Recipe
  >
  | RecipesActionType<
    typeof RecipesActions.REMOVE_RECIPE_SUCCESS,
    string
  >
  | RecipesActionType<
    typeof RecipesActions.REMOVE_RECIPE_FAIL,
    string
  >;

export const initialRecipesState: RecipesState = {
  hasError: false,
  loading: false,
  loaded: false,
  recipes: [],
};
