import { RecipesActions, RecipesAction } from '../model/recipes.state';
import { Recipe } from '../model/recipes.model';
import { RecipeRow } from '../types/recipe-row';


export function getRecipes(): RecipesAction {
	return { type: RecipesActions.GET_RECIPES, payload: '' };
}

export function getRecipesSuccess(recipes: RecipeRow[]): RecipesAction {
    console.log(recipes)
	return { type: RecipesActions.GET_RECIPES_SUCCESS, payload: recipes };
}

export function getRecipesFail(error: string): RecipesAction {
	return { type: RecipesActions.GET_RECIPES_FAIL, payload: error };
}

export function addRecipe(recipe: Recipe): RecipesAction {
	return { type: RecipesActions.ADD_RECIPE, payload: recipe };
}

export function addRecipeSuccess(recipe: RecipeRow): RecipesAction {
	return { type: RecipesActions.ADD_RECIPE_SUCCESS, payload: recipe };
}

export function addRecipeFail(error: string): RecipesAction {
	return { type: RecipesActions.ADD_RECIPE_FAIL, payload: error };
}

export function removeRecipe(recipe: Recipe): RecipesAction {
	return { type: RecipesActions.REMOVE_RECIPE, payload: recipe };
}

export function removeRecipeSuccess(): RecipesAction {
	return { type: RecipesActions.REMOVE_RECIPE_SUCCESS, payload: '' };
}

export function removeRecipeFail(error: string): RecipesAction {
	return { type: RecipesActions.REMOVE_RECIPE_FAIL, payload: error };
}



