import { RecipesActions, RecipesState, RecipesAction, initialRecipesState } from '../model/recipes.state';
import createReducer  from './createReducer';

  export const recipesReducer = createReducer<RecipesState>(initialRecipesState, {
    [RecipesActions.GET_RECIPES](state: RecipesState, action: RecipesAction) {
		return {...state, loading: true, loaded: false, recipes: []};
	},
    [RecipesActions.GET_RECIPES_SUCCESS](state: RecipesState, action: RecipesAction) {
        console.log(JSON.stringify(action.payload))
		return {...state, loading: false, loaded: true, hasError: false, recipes: action.payload};
	},
	[RecipesActions.GET_RECIPES_FAIL](state: RecipesState, action: RecipesAction) {
		return {...state, loading: false, loaded: true, hasError: true};
	},
	[RecipesActions.ADD_RECIPE](state: RecipesState, action: RecipesAction) {
		const updatedRecipes = state.recipes;
		if(action.type === RecipesActions.ADD_RECIPE) {
			updatedRecipes.push(action.payload)
		}
		return {...state, loading: true, loaded: false, recipes: updatedRecipes};
	},
    [RecipesActions.ADD_RECIPE_SUCCESS](state: RecipesState, action: RecipesAction) {
		const addedRecipes = state.recipes.map(recipe => recipe);
		if(action.type === RecipesActions.ADD_RECIPE_SUCCESS) {
			console.log(action.payload)
			addedRecipes.push(action.payload)
		}
		return {...state, loading: false, loaded: true, hasError: false, recipes: addedRecipes};
	},
	[RecipesActions.ADD_RECIPE_FAIL](state: RecipesState, action: RecipesAction) {
		return {...state, loading: false, loaded: true, hasError: true};
	},
	[RecipesActions.REMOVE_RECIPE](state: RecipesState, action: RecipesAction) {
		const updatedRecipes = state.recipes.map(recipe => recipe);
		if(action.type === RecipesActions.REMOVE_RECIPE) {
			const i = state.recipes.indexOf(action.payload);
			if(i >= 0) {
				updatedRecipes.splice(i,1);
			}
		}
		return {...state, loading: true, loaded: false, recipes: updatedRecipes};
	},
    [RecipesActions.REMOVE_RECIPE_SUCCESS](state: RecipesState, action: RecipesAction) {
		return {...state, loading: false, loaded: true, hasError: false};
	},
	[RecipesActions.REMOVE_RECIPE_FAIL](state: RecipesState, action: RecipesAction) {
		return {...state, loading: false, loaded: true, hasError: true};
	}

});
