import { History } from "history";
import { combineReducers } from "redux";
import * as recipesReducer  from "./recipes.reducer";
import { RecipesState } from "../model/recipes.state";
import * as cartReducer  from "./cart.reducer";
import { CartState } from "../model/cart.state";




export interface RootState {
	recipeReducer: RecipesState;
	cartReducer: CartState;
}

export default (history: History) =>
	combineReducers({
		...recipesReducer,
		...cartReducer
	});
