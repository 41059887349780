import { Box, Button, Grid, IconButton, Modal, TextField, Typography, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useActions } from '../actions';
import * as CartActions from '../actions/cart.actions';
import { DataGrid, GridRowId } from '@mui/x-data-grid';
import * as RecipesActions from '../actions/recipes.action';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { get as _get } from 'lodash';
import { addRecipe, fetchFromCloud, fetchFromCsv, mapIngredientsToDisplayString, updateCart } from '../services/excel-reader.service';
import { Recipe } from '../model/recipes.model';
import { renderCellExpand } from '../components/render-cell-expand';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RemoveIcon from '@mui/icons-material/Remove';
import { Ingredient, RecipeRow } from '../types/recipe-row';
import { mapListToTableFormat } from '../mappers/ingredient.mapper';
import { getItem, setItem } from '../services/cookies.service';

const quantities = [
	{
		key: 'q',
		value: ''
	},
	{
		key: 'oz',
		value: 'oz'
	},
	{
		key: 'tbs',
		value: 'tablespoons'
	},
	{
		key: 'tsps',
		value: 'teaspoons'
	},
	{
		key: 'c',
		value: 'cups'
	},
	{
		key: 'tt',
		value: 'to taste'
	},
	{
		key: 'lb',
		value: 'pound'
	}
]

const columns = [
	{
		field: 'name',
		headerName: 'Name',
		minWidth: 150,
		flex: 300,
		editable: false,
		resizable: true,
		renderCell: renderCellExpand,
	},
	{
		field: 'ingredientsDisplay',
		headerName: 'Ingredients List',
		minWidth: 150,
		flex: 1000,
		editable: false,
		resizable: true,
		renderCell: renderCellExpand,
	},
	{
		field: 'instructions',
		headerName: 'Instructions',
		minWidth: 150,
		flex: 1000,
		editable: false,
		resizable: true,
		renderCell: renderCellExpand,
	},
];

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	minHeight: 600,
	display: 'block',
	overflow: "auto",
	scrollY: '',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export function TodoPage() {
	const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
	const [open, setOpen] = React.useState(false);
	const [addRecipeName, setAddRecipeName] = React.useState('');
	const [addRecipeInstructions, setAddRecipeInstructions] = React.useState('');
	const [ingredients, setIngredients] = React.useState<Ingredient[]>([{ name: '', quantity: '', unit: 'q' }]);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleAddIngredient = () => setIngredients(ingredients.concat([{ name: '', quantity: '', unit: 'q' }]));

	// Auth Block ///////////////////////////////////////////////////////////////
	const [user, loading] = useAuthState(auth);
	// const [name, setName] = useState("");
	const navigate = useNavigate();
	const recipesActions: typeof RecipesActions = useActions(RecipesActions);

	const fetchUserName = async () => {
		try {
			const q = query(collection(db, "users"), where("uid", "==", user?.uid));
			console.log(auth)

			console.log(q)
			const doc = await getDocs(q);
			console.log(doc)
			//setName(data.name);
		} catch (err) {
			console.error(err);
			alert("An error occured while fetching user data");
		}
	};

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
		// getRecipesFromAWS(recipesActions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.currentUser]);

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
		fetchUserName();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, loading]);
	// console.log(name)
	////////////////////////////////////////////////////////////////////////////////////


	const recipeData = useSelector((state: RootState) => _get(state, 'recipesReducer'));
	let rows = useSelector((state: RootState) => _get(state, 'recipesReducer.recipes', []));


	const cartActions: typeof CartActions = useActions(CartActions);
	const classes = useStyles();

	const handleAddTodo = () => {

		const recipesToCart: Recipe[] = [];
		let currentCart = getItem('cart') || '';

		selectionModel.forEach(rowId => {
			const elToAdd = rows.find((row: any) => row.id === rowId);
			recipesToCart.push(elToAdd)
			currentCart += elToAdd.id.toString() + ',';
		});

		let cartData = currentCart.split(',').map(itemId => {
			return rows.find((item: { id: number; }) => item.id === parseInt(itemId))
		});

		cartData = cartData.filter(Boolean);

		cartActions.addToCart(recipesToCart);
		updateCart(_get(auth, 'currentUser.accessToken', ''), cartData);
		setItem('cart', currentCart);
	};

	const handleAddRecipe = () => () => {
		console.log(ingredients);
		let i = '';

		ingredients.forEach(ingredient => i = i + ingredient.name + '.' + ingredient.quantity + ' ' + ingredient.unit + '. ,')
		const newRecipe = {
			recipeId: rows.length,
			ingredients: i,
			name: addRecipeName,
			instructions: addRecipeInstructions
		}
		addNewRecipe(recipesActions, newRecipe);
	}

	const addIngredientToList = () => () => {
		handleAddIngredient();
	}

	const removeIngredientFromList = (index: number) => () => {
		console.log(index);
		const updatedIngredients = ingredients.slice();
		updatedIngredients.splice(index, 1)
		console.log(updatedIngredients)
		setIngredients(updatedIngredients);

	}

	const updateIngredientValue = (e: { target: { value: string; }; }, id: number) => {
		const { value } = e.target;
		const updatedIngredients = ingredients.map(object => object)
		updatedIngredients[id].name = value;
		console.log(updatedIngredients)

		setIngredients(updatedIngredients);
		console.log('ingredients value')
		console.log(ingredients)
	};



	const updateIngredientQuantity = (e: { target: { value: string; }; }, id: number) => {
		const { value } = e.target;
		const updatedIngredients = ingredients.map(object => object)
		updatedIngredients[id].quantity = value;
		console.log(updatedIngredients)

		setIngredients(updatedIngredients);
		console.log('ingredients quantity')
		console.log(ingredients)
	};

	

	const updateIngredientUnit = (e: { target: { value: string; }; }, id: number) => {
		const { value } = e.target;
		const updatedIngredients = ingredients.map(object => object)
		updatedIngredients[id].unit = value;
		console.log(updatedIngredients)

		setIngredients(updatedIngredients);
		console.log('ingredients unit')
		console.log(ingredients)
	};





	let started = false;

	if (recipeData && !recipeData?.loading && !recipeData?.hasError && !recipeData?.loaded && !started && auth.currentUser) {
		started = true;
		console.log('boop');
		recipesActions.getRecipes();
		getRecipesFromAWS(recipesActions, cartActions);
	}

	return (
		<Grid container className={classes.root}>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Grid container>
						<Grid item xs={12}>
							<Typography id="add-item-modal-title" variant="h6" component="h2">
								Recipe to add:
							</Typography>
						</Grid>
						<Grid item xs={12}>

							<TextField sx={{ marginBottom: '10px', width: '100%' }} value={addRecipeName} onChange={(v) => setAddRecipeName(v.target.value)} id="itemName" label="Recipe Name" variant="outlined" />
						</Grid>
						<Grid item xs={12}>

							<TextField sx={{ marginBottom: '10px', width: '100%' }} value={addRecipeInstructions} multiline={true} rows={5} onChange={(v) => setAddRecipeInstructions(v.target.value)} id="itemQuantity" label="Instructions" size='medium' variant="outlined" />
						</Grid>
						{ingredients.map((ingredient, index) => {

							return (
								<>
									<Grid item xs={6} sx={{ paddingRight: '1em' }}>
										<TextField value={ingredient.name} sx={{ marginBottom: '10px', width: '100%' }} onChange={(v) => {
											updateIngredientValue(v, index);
										}} id={"itemName" + index.toString()} label="Ingredients" size='medium' variant="outlined" />

									</Grid>

									<Grid item xs={2} sx={{ paddingRight: '1em' }}>
										<TextField value={ingredient.quantity} sx={{ marginBottom: '10px' }} onChange={(v) => {
											updateIngredientQuantity(v, index);
										}} id={"itemQuantity" + index.toString()} label="#" variant="outlined" />

									</Grid>

									<Grid item xs={2}>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">Unit</InputLabel>
											<Select
												labelId="unit-simple-select-label"
												id="unit-simple-select"
												value={ingredient.unit}
												label="Unit"
												onChange={(v) => {
													updateIngredientUnit(v, index);}}
											>
												{quantities.map((quantity: any) => 
													<MenuItem value={quantity.key}>{quantity.value}</MenuItem>
												)}
											</Select>
										</FormControl>
									</Grid>


									<Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={2}>
										{(index === ingredients.length - 1) &&
											<IconButton onClick={addIngredientToList()}
											>
												<AddIcon></AddIcon></IconButton>
										}

										{(index < ingredients.length - 1) &&
											<IconButton onClick={removeIngredientFromList(index)}
											>
												<RemoveIcon></RemoveIcon></IconButton>
										}

									</Grid>

								</>)
						}
						)}
						<Grid item xs={12}>
							<Button className={classes.button} style={{ marginTop: '5px' }} variant="contained" color="primary" onClick={handleAddRecipe()}>
								Add Recipe
							</Button>
						</Grid>







					</Grid>
				</Box>
			</Modal>
			<Grid item xs={6}>
				<Typography variant="h4" gutterBottom>
					Recipe List
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<div className={classes.buttonContainer}>
					<Button className={classes.button} variant="contained" color="primary" onClick={handleOpen}>
						Add Recipe
					</Button>
				</div>
				<div className={classes.buttonContainer}>
					<Button className={classes.button} variant="contained" color="primary" onClick={handleAddTodo}>
						Add To Cart
					</Button>
				</div>
			</Grid>
			<Grid item xs={12}>
				<div style={{ width: '100%' }}>
					<DataGrid
						autoHeight={true}
						rows={rows}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5]}
						checkboxSelection
						disableSelectionOnClick
						onSelectionModelChange={(newSelection) => {
							console.log(newSelection)
							setSelectionModel(newSelection);
						}}

					/>
				</div>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 20,
		[theme.breakpoints.down('md')]: {
			paddingTop: 50,
			paddingLeft: 15,
			paddingRight: 15,
		},
	},

	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},

	button: {
		marginBottom: 15,
	},
}));



async function getRecipesFromAWS(recipeActions: typeof RecipesActions, cartActions: typeof CartActions) {
	let data: any = await fetchFromCloud(_get(auth, 'currentUser.accessToken', ''), _get(auth, 'currentUser.email', ''));


	console.log('data')
	console.log(data)


	if (!data) {
		console.log('missed')
		data = await fetchFromCsv();
	}

	const cookies = getItem('cart');
	console.log(cookies);
	if (cookies) {
		let prefillCart = cookies.split(',').map(itemId => {
			return data.find((item: any) => item.id === parseInt(itemId));
		});

		prefillCart = prefillCart.filter(Boolean);

		if (prefillCart) {
			cartActions.addToCart(prefillCart);
		}
	}


	recipeActions.getRecipesSuccess(data);
}

async function addNewRecipe(actions: typeof RecipesActions, recipeBody: any) {
	let data: any = await addRecipe(_get(auth, 'currentUser.accessToken', ''), recipeBody);

	const formattedRecipe: RecipeRow = {
		id: recipeBody.recipeId,
		name: recipeBody.name,
		ingredients: recipeBody.ingredients,
		ingredientsDisplay: mapListToTableFormat(mapIngredientsToDisplayString(recipeBody.ingredients.split(','))),
		instructions: recipeBody.instructions
	}


	console.log('data')
	console.log(data)


	if (!data) {
		console.log('cant add')
		actions.addRecipeFail('err');

	} else {
		actions.addRecipeSuccess(formattedRecipe);

	}
}

// async function removeRecipe(actions: typeof RecipesActions, recipeId: string) {
// 	let data: any = await removeRecipe(_get(auth, 'currentUser.accessToken', ''), recipeId);


// 	console.log('data')
// 	console.log(data)


// 	if (!data) {
// 		console.log('cant add')
// 		actions.removeRecipeFail('err');

// 	} else {
// 		actions.removeRecipeSuccess();

// 	}
// }
