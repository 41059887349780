import { CartRow } from './cart-row.model';
import { Recipe } from './recipes.model';

export interface CartState {
    hasError: boolean;
    loading: boolean;
    loaded: boolean;
    recipeList: Recipe[],
    groceryList: CartRow[];
}

export enum CartActions {
  ADD_TO_CART = "ADD_TO_CART",
  CHECK_IN_CART = "CHECK_IN_CART",
  UNCHECK_IN_CART = "UNCHECK_IN_CART",
  REMOVE_FROM_CART = "REMOVE_FROM_CART",
  CLEAR_CART = "CLEAR_CART",
  ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART",
  REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART",
  LOAD_COOKIES = "LOAD_COOKIES"
}

interface CartActionType<T, P> {
  type: T;
  payload: P;
}

export type CartAction =
  | CartActionType<typeof CartActions.ADD_TO_CART, Recipe[]>
  | CartActionType<
      typeof CartActions.CHECK_IN_CART,
      number
    >
    | CartActionType<
    typeof CartActions.UNCHECK_IN_CART,
    number
  >
  | CartActionType<
      typeof CartActions.REMOVE_FROM_CART,
      number
    >
    | CartActionType<
      typeof CartActions.CLEAR_CART,
      null>
      | 
      CartActionType<
      typeof CartActions.ADD_ITEM_TO_CART,
      CartRow
    >
    | CartActionType<
      typeof CartActions.REMOVE_ITEM_FROM_CART,
      number
    >| CartActionType<
    typeof CartActions.LOAD_COOKIES,
    {recipes: any, groceryList: any}
  >;

export const initialCartState: CartState = {
    hasError: false,
    loading: false,
    loaded: false,
    recipeList: [],
    groceryList: [],
};
