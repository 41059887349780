import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { get as _get } from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CartRow } from '../model/cart-row.model';
import { useActions } from '../actions';
import * as CartActions from '../actions/cart.actions';
import { Box, Button, Grid, IconButton, Modal, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Recipe } from '../model/recipes.model';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateCart } from '../services/excel-reader.service';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export function CartPage() {
	const cartActions: typeof CartActions = useActions(CartActions);
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [openAddItem, setOpenAddItem] = React.useState(false);

	const [addItemName, setAddItemName] = React.useState('');
	const [addItemQuantity, setAddItemQuantity] = React.useState('');


	// Auth Block ///////////////////////////////////////////////////////////////
	const [user, loading] = useAuthState(auth);
	// const [name, setName] = useState("");
	const navigate = useNavigate();
	// const fetchUserName = async () => {
	//   try {

	// 	const q = query(collection(db, "users"), where("uid", "==", user?.uid));
	// 	console.log(q)
	// 	const doc = await getDocs(q);
	// 	console.log(doc)
	// 	// const data = doc.docs[0].data();
	// 	// setName(data.name);
	//   } catch (err) {
	// 	console.error(err);
	// 	alert("An error occured while fetching user data");
	//   }
	// };
	useEffect(() => {
	  if (loading) return;
	  if (!user) return navigate("/");
	//   fetchUserName();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, loading, navigate]);
	// console.log(name)

	////////////////////////////////////////////////////////////////////////////////////

	const [modalText, setModalText] = React.useState<string[]>([]);


	let cartData = useSelector((state: RootState) => _get(state, 'cartReducer.groceryList', []));

	let recipeList = useSelector((state: RootState) => _get(state, 'cartReducer.recipeList', []));

	const [checked, setChecked] = React.useState<number[]>([]);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			cartActions.checkInCart(value);
			newChecked.push(value);
		} else {
			cartActions.uncheckInCart(value);
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};



	const handleRemove = (value: Recipe) => () => {
		const updatedCart: any[] = [];

		recipeList.forEach((item: any) => {
			console.log('item');
			console.log(item)
			if(item.id !== value.id) {
				updatedCart.push(item)
			}
		})

		updateCart(_get(auth, 'currentUser.accessToken', ''), updatedCart);
		cartActions.removeFromCart(value.id);
	};

	const clearCart = () => () => {
		updateCart(_get(auth, 'currentUser.accessToken', ''), []);

		cartActions.clearCart();
	};


	const handleOpen = (value: CartRow) => () => {
		setModalText(mapDetailedModalText(_get(value, 'detailedIngredient', [])));
		setOpen(true);
	};


	const handleAddItem  = () => () => {
		cartActions.addItemToCart({id: Math.random(), ingredient: addItemName, quantity: addItemQuantity, isIndividual: true});
		handleAddItemClose();
	}



	

	const handleRemoveItem = (value: any) => () => {
		cartActions.removeItemFromCart(value);
	}



	const handleClose = () => setOpen(false);
	const handleAddItemClose = () => setOpenAddItem(false);
	const handleAddItemOpen = () => setOpenAddItem(true);


	const mapDetailedModalText = (value: CartRow[]) => () => {
		let mappedIngredients: string[] = [];
		value.forEach((ingredient) => {
			mappedIngredients.push(ingredient.quantity + ' ' + ingredient.ingredient);
		});
		return mappedIngredients;
	};


	return (
		<Grid container className={classes.root}>
			<Grid item xs={6}>
				<Typography variant="h4" gutterBottom>
					Recipe List
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<div className={classes.buttonContainer}>
					<Button className={classes.button} variant="contained" color="primary" component={Link} to={'/'}>
						Go to List
					</Button>
				</div>
			</Grid>
			{recipeList.map((recipe: Recipe) => {
				return (
					<>
						<Grid item xs={9}>
							{recipe.name}
						</Grid>
						<Grid item xs={3}>
							<div className={classes.buttonContainer}>
								<Button className={classes.button} variant="contained" color="primary" onClick={handleRemove(recipe)}>
									Remove Recipe
								</Button>
							</div>
						</Grid>
					</>
				);
			})}
			<Grid item xs={6}>
				<Typography variant="h4" gutterBottom>
					Ingredients List
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<div className={classes.buttonContainer}>
				<Button className={classes.button} style={{marginRight: '5px'}} variant="contained" color="primary" onClick={handleAddItemOpen}>
						Add Individual Item to Cart
					</Button>
					<Button className={classes.button} variant="contained" color="primary" onClick={clearCart()}>
						Clear Cart
					</Button>
				</div>
			</Grid>
			<Grid item xs={12}>
				<div style={{ width: '100%' }}>
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						{cartData.map((value: CartRow) => {
							const labelId = `checkbox-list-label-${value}`;

							return (
								<ListItem key={value.id} disablePadding>
									<ListItemButton role={undefined} onClick={handleToggle(value.id)} dense>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={checked.indexOf(value.id) !== -1}
												tabIndex={-1}
												disableRipple
												inputProps={{ 'aria-labelledby': labelId }}
											/>
										</ListItemIcon>
										<ListItemText id={labelId} primary={`${value.quantity} ${value.ingredient}`} />
									</ListItemButton>{ value.isIndividual ?							<IconButton onClick={handleRemoveItem(value.id)}>
							<CancelIcon></CancelIcon></IconButton>  : <ListItemButton onClick={handleOpen(value)}>										<ListItemText id={labelId + '-show-more'} primary={`Show Detailed`} />
									</ListItemButton>} 
									
								</ListItem>
							);
						})}
					</List>
				</div>
			</Grid>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="detailed-ingredient-modal"
				aria-describedby="detailed-ingredient-modal"
			>
				<Box sx={style}>
					<Typography id="detailed-ingredient-modal-title" variant="h6" component="h2">
						Detailed Ingredient Breakdown:
					</Typography>
					{modalText.map(ingredient => {
						return <Typography id="detailed-ingredient-modal-description" sx={{ mt: 2 }}>
							{ingredient}
						</Typography>
					})}
				</Box>
			</Modal>


			<Modal
				open={openAddItem}
				onClose={handleAddItemClose}
				aria-labelledby="add-item-modal"
				aria-describedby="add-item-modal"
			>
				<Box sx={style}>
					<Typography id="add-item-modal-title" variant="h6" component="h2">
						Item to add:
					</Typography>
					<TextField onChange={(v) => setAddItemName(v.target.value) } id="itemName" label="Item Name" variant="outlined" />
					<TextField onChange={(v) => setAddItemQuantity(v.target.value) } id="itemQuantity" label="Quantity" variant="outlined" />
					<Button className={classes.button} style={{marginTop: '5px'}} variant="contained" color="primary" onClick={handleAddItem()}>
						Add Item
					</Button>
				</Box>
			</Modal>
		</Grid>

	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 20,
		[theme.breakpoints.down('md')]: {
			paddingTop: 50,
			paddingLeft: 15,
			paddingRight: 15,
		},
	},

	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},

	button: {
		marginBottom: 15,
	},
}));
